import React from 'react'

const ContactButton = ({ onClick }) => {

    return (
        <div className="flex justify-center md:ml-3">
            <button
                onClick={onClick}
                className="group relative bg-white md:bg-transparent inline-flex items-center justify-center overflow-hidden rounded-xl border-2 border-primary hover:border-primary p-4 px-5 py-2.5 font-medium text-white shadow-md transition duration-300 ease-out"
            >
                <span className="absolute inset-0 flex h-full w-full -translate-y-full items-center justify-center md:bg-primary bg-secondary text-white duration-300 group-hover:translate-y-0">
                    <svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24"
                        viewBox="0 0 24 24"
                    >
                        <path fill="currentColor" d="m23 11.5l-3.05-1.13c-.26-1.15-.91-1.81-.91-1.81a4.19 4.19 0 0 0-5.93 0l-1.48 1.48L5 3c-1 4 0 8 2.45 11.22L2 19.5s8.89 2 14.07-2.05c2.76-2.16 3.38-3.42 3.77-4.75zm-5.29.22c-.39.39-1.03.39-1.42 0a.996.996 0 0 1 0-1.41c.39-.39 1.03-.39 1.42 0s.39 1.02 0 1.41" />
                    </svg>
                </span>
                <span className="absolute flex h-full w-full transform items-center justify-center text-primary md:text-primary transition-all duration-300 group-hover:translate-y-full">
                    Contacto
                </span>
                <span className="invisible relative">Contacto</span>
            </button>
        </div>
    )
}

export default ContactButton