import React from 'react';
import './LoadingScreen.css';
import poster from '../../../assets/images/poster2.jpg'
import posterbg from '../../../assets/images/poster-bg2.jpg'

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <div className="loading-background"  style={{backgroundImage: `url(${posterbg})`}} ></div>
            <div className="loading-content">
                <img src={poster} alt="Cargando..." />
                <p>Cargando...</p>
                {/* <p style={{margin:0, backgroundColor:"black"}}>PRÓXIMAMENTE</p> */}
            </div>
        </div>
    );
}

export default LoadingScreen;