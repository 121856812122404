import React from 'react';

import { Unity, useUnityContext } from "react-unity-webgl";

const CameraGame = () => {
    const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
        loaderUrl: "/minigames/fotos/Build/WEB-aves.loader.js",
        dataUrl: "/minigames/fotos/Build/WEB-aves.data",
        frameworkUrl: "/minigames/fotos/Build/WEB-aves.framework.js",
        codeUrl: "/minigames/fotos/Build/WEB-aves.wasm",
    });

    return (
        <div className='h-screen w-full'>
            {!isLoaded && (
                <div className='w-full h-full flex justify-center items-center flex-col'>
                    {/* <div className="radial-progress"
                        style="--value:70; --size:12rem; --thickness: 2rem;"
                        role="progressbar"
                    >
                        {Math.round(loadingProgression * 100)}%
                    </div> */}
                    <progress className="progress progress-primary w-56" value={Math.round(loadingProgression * 100)} max="100"></progress>
                    <p className='text-primary font-semibold text-lg mt-4'>
                        {`Cargando ${Math.round(loadingProgression * 100)}%`}
                    </p>
                </div>

            )}
            <Unity
                unityProvider={unityProvider}
                style={{
                    visibility: isLoaded ? "visible" : "hidden",
                    paddingTop:'5rem',
                    width: '100%',
                    height: '100%'
                }}
            />
        </div>
    );
}

export default CameraGame;