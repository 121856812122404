import aerial2 from '../../assets/images/test/aerial2-min.jpg'
//import aerial2t from '../../assets/images/test/aerial2t-min.png'
import aerial2bump from '../../assets/images/test/newBump1.jpg'
import aerial2alpha from '../../assets/images/test/alphaMap.jpg'



window.AFRAME.registerComponent('double-side-material', {
    init: function () {
      var el = this.el;
      el.addEventListener('model-loaded', function () {
        var model = el.getObject3D('mesh');
        model.traverse(function (node) {
          if (node.isMesh) {
            node.material.side = window.THREE.DoubleSide;
          }
        });
      });
    }
  });

  window.AFRAME.registerComponent('carpintero-material', {
    /* schema: {
      texture: {type: 'string', default: ''}
    }, */
  
    init: function () {
      var el = this.el;
      var texture = this.data.texture;
  
      el.addEventListener('model-loaded', function () {
        var model = el.getObject3D('mesh');
        if (model) {
          model.traverse(function (node) {
            
            if (node.isMesh) {
              //console.log(node)
              if(node.name !=="eagle3"){
                node.visible = false
              }
            }
          });
        }
      });
    }
  });

  window.AFRAME.registerComponent('cotorra-material', {
  
    init: function () {
      var el = this.el;
  
      el.addEventListener('model-loaded', function () {
        var model = el.getObject3D('mesh');
        if (model) {
          model.traverse(function (node) {

            if(node.name === "cs_grp"){
              
              node.visible=false
            }
            
            if (node.isMesh && node.material.name === "Cotorra") {
              //console.log(node.material);
              node.material.transparent = false;
              node.material.opacity = 1; 
              node.material.depthWrite = true;
              node.material.side = window.THREE.DoubleSide;
              node.material.needsUpdate = true;
            }
          });
        }
      });
    }
  });

  window.AFRAME.registerComponent('rupornis-material', {
    init: function () {
      var el = this.el;
  
      el.addEventListener('model-loaded', function () {
        var model = el.getObject3D('mesh');
        if (model) {
          model.traverse(function (node) {
            
            if (node.isMesh && node.geometry.attributes.uv1) { // el segundo conjunto de UV existe
              
              node.geometry.setAttribute('uv', node.geometry.attributes.uv1);
              node.material.transparent = false;
              node.material.opacity = 1; 
              node.material.depthWrite = true;
              node.material.side = window.THREE.DoubleSide;
              node.material.needsUpdate = true;
             
            }
          });
        }
      });
    }
  });


  window.AFRAME.registerComponent('mapa-material', {
    schema: {
      texture: { type: 'string', default: '' }
    },
  
    init: function () {
      var el = this.el;
      var textureURL = this.data.texture;
  
      el.addEventListener('model-loaded', function () {
        var model = el.getObject3D('mesh');
        if (model) {
          var textureLoader = new window.THREE.TextureLoader();
          textureLoader.load(textureURL, function (texture) {
            texture.flipY = false;
            texture.encoding = window.THREE.sRGBEncoding;
            model.traverse(function (node) {
              if (node.isMesh) {
                node.material.map = texture;
                node.material.needsUpdate = true;

                node.material.transparent = true; // Activar la transparencia
              node.material.alphaTest = 0.5; // Opcional: ajustar umbral de transparencia
              }
            });
          });
        }
      });
    }
  });

  window.AFRAME.registerComponent('test-materials', {
    schema: {
      type: { type: 'string', default: '' }
    },
  
    init: function () {
      var el = this.el;
      var type = this.data.type;

      var textures = {
      
        aerial2: {
          texture: aerial2,
          bumpMap: aerial2bump,
          displacementScale: 0.09,
          invertY:false,
          alpha: aerial2alpha
        },
        aerial2i: {
          texture: aerial2,
          bumpMap: aerial2bump,
          displacementScale: 0.09,
          invertY:true,
          alpha: aerial2alpha
        }

      };
  
      var selectedTexture = textures[type];
      
        var model = el.getObject3D('mesh');
        
        if (model) {
          
          var textureLoader = new window.THREE.TextureLoader();

          var alphaMap = textureLoader.load(selectedTexture.alpha);

          textureLoader.load(selectedTexture.texture, function (texture) {
           /*  texture.flipY = false; */
            texture.encoding = window.THREE.sRGBEncoding;
             // Cargar el mapa de bump
             textureLoader.load(selectedTexture.bumpMap, function (bumpMap) {

              if(selectedTexture.invertY){
                texture.flipY = false;
                bumpMap.flipY = false;
                alphaMap.flipY = false;
                //console.log(selectedTexture)
              }

              model.traverse(function (node) {
                if (node.isMesh) {
                  node.material.map = texture;
                  node.material.displacementMap = bumpMap;
                  node.material.displacementScale = selectedTexture.displacementScale; // Ajustar la escala del bump 0.08
                  node.material.alphaMap = alphaMap;
                  node.material.color.set(0xffffff);
                  node.material.metalness=0;
                  node.material.roughness=1;
                  node.material.needsUpdate = true;


                  node.material.transparent = true; // Activar la transparencia
                  node.material.alphaTest = 0.5;
                }
              });
            });
          });
        }
      
    }
  });