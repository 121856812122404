window.AFRAME.registerComponent('bird-movement', {
    schema: {
      duration: { type: 'number', default: 5000 },
      delay: { type: 'number', default: 0 }
    },
  
    init: function () {
      this.el.object3D.position.set(0, 0, 0);
      this.startTime = null;
      this.curve = this.createCurve();
    },
  
    createCurve: function () {
        const randomInRange = (min, max) => Math.random() * (max - min) + min;
    
        // Crear una ruta no lineal usando Bezier curves dentro del área especificada
        const start = new window.THREE.Vector3(
          randomInRange(-0.5, 0.5),
          randomInRange(0.08, 0.08),
          randomInRange(-0.5, 0.5)
        );
        const control1 = new window.THREE.Vector3(
          randomInRange(-0.5, 0.5),
          randomInRange(0.08, 0.08),
          randomInRange(-0.5, 0.5)
        );
        const control2 = new window.THREE.Vector3(
          randomInRange(-0.5, 0.5),
          randomInRange(0.08, 0.08),
          randomInRange(-0.5, 0.5)
        );
        const end = new window.THREE.Vector3(
          randomInRange(-0.5, 0.5),
          randomInRange(0.08, 0.08),
          randomInRange(-0.5, 0.5)
        );
        console.log(control1)
        return new window.THREE.CubicBezierCurve3(start, control1, control2, end);
      },
  
    tick: function (time) {
      if (!this.startTime) {
        this.startTime = time + this.data.delay;
      }
  
      const elapsed = time - this.startTime;
      if (elapsed < 0) return;
  
      const t = Math.min(elapsed / this.data.duration, 1);
      const position = this.curve.getPoint(t);
  
      this.el.object3D.position.copy(position);
  
      if (t === 1) {
        // Animación completada, reiniciar o eliminar la entidad
        this.startTime = null; // Reiniciar la animación
      }
    }
  });