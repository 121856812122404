import React from 'react';

// sections
import SectionHero from './SectionHero';
import SectionRealism from './SectionRealism';
import SectionLandscapes from './SectionLandscapes';
import SectionTestimonies from './SectionTestimonies';
import SectionStages from './SectionStages';

const About = () => {
    return (
        <div className='w-full h-full md:p-20 pt-20'>
            <SectionHero/>
            <SectionRealism/>
            <SectionLandscapes/>
            <SectionTestimonies/>
            <SectionStages/>
        </div>
    )
}

export default About;