import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

import PanoramaUI from './PanoramaUI';

// assets
import panorama_1 from '../../assets/images/panoramas/Panorama_1.jpg'

const Panorama = () => {

    // Obtengo el id del panorama de la url
    let { panoId } = useParams();

    useEffect(() => {
        // console.log(panoId)
    }, [])


    return (
        <div className='w-full h-full'>
            <div className='absolute w-full h-full'>
                <ReactPhotoSphereViewer
                    src={panorama_1}
                    height={'100vh'}
                    width={"100%"}
                    touchmoveTwoFingers={false}
                    mousewheel={true}
                    loadingTxt='Cargando'
                    navbar={false}
                ></ReactPhotoSphereViewer>
            </div>

            <div className='absolute w-full h-full pointer-events-none'>
                <PanoramaUI />
            </div>

        </div>

    )
}

export default Panorama;