import React from 'react';

import { motion } from 'framer-motion';

import SectionTitle from '../../components/react-components/SectionTitle/SectionTitle';

// assets
import logo_icesi from '../../assets/images/logos/aliados/aliado_icesi_azul.png';
import logo_andes from '../../assets/images/logos/aliados/aliado_andes_color.png';
import logo_univalle from '../../assets/images/logos/aliados/aliado_univalle.jpg';
import logo_uao from '../../assets/images/logos/aliados/aliado_uao.png';
import logo_uninorte from '../../assets/images/logos/aliados/aliado_uninorte.png';
import logo_birfair from '../../assets/images/logos/aliados/aliado_birdfair.webp';
import logo_ornitologia from '../../assets/images/logos/aliados/aliado_ornitologia_univalle.png';
import logo_dacnis from '../../assets/images/logos/aliados/aliado_dacnis.png';

export const Logo = ({ image }) => {
    return (
        <div className="px-2 py-3 h-full w-full">
            <div className="bg-white px-5 py-3 flex items-center justify-center rounded-lg h-full w-full">
                <img loading="lazy"
                    src={image}
                    className=" object-contain object-center h-[100px] w-full overflow-hidden self-center" alt="logo"
                />
            </div>

        </div>

    )
}

// Define the array of slides with numbers
const slides = [
    { src: logo_icesi },
    { src: logo_andes },
    { src: logo_univalle },
    { src: logo_uao },
    { src: logo_uninorte },
    { src: logo_birfair },
    { src: logo_ornitologia },
    { src: logo_dacnis },
];

const SectionAlliesList = () => {
    // Duplicate the slides array to ensure seamless looping
    const duplicatedSlides = [...slides, ...slides];

    return (
        <section className="text-gray-600 body-font">
            <div className="container flex flex-col px-5 py-16 justify-center items-center">

                <SectionTitle title='Aliados' className='justify-center mb-8' />

                <div className="relative w-full overflow-hidden
                    [mask-image:_linear-gradient(to_right,transparent_0,_black_80px,_black_calc(100%-200px),transparent_100%)]">
                    {/* Wrapping div for seamless looping */}
                    <motion.div
                        className="flex"
                        animate={{
                            x: ['-150%', '0%'],
                            transition: {
                                ease: 'linear',
                                duration: 24,
                                repeat: Infinity,
                            }
                        }}
                    >
                        {/* Render duplicated slides */}
                        {duplicatedSlides.map((slide, index) => (
                            <div key={index} className="flex-shrink-0" style={{ width: `${150 / slides.length}%` }}>
                                <Logo image={slide.src} />
                            </div>
                        ))}
                    </motion.div>
                </div>
            </div>


        </section>
    )
}

export default SectionAlliesList;