window.AFRAME.registerComponent('wobble-normal', {
    schema: {},
    tick: function (t) {
      if (!this.el.components.material.material.normalMap) return;
      this.el.components.material.material.normalMap.offset.x += 0.0001 * Math.sin(t/10000);
      this.el.components.material.material.normalMap.offset.y += 0.0001 * Math.cos(t/8000);
      this.el.components.material.material.normalScale.x = 0.5 + 0.5 * Math.cos(t/1000);
      this.el.components.material.material.normalScale.x = 0.5 + 0.5 * Math.sin(t/1200);
    }
  })
  
  window.AFRAME.registerPrimitive('a-ocean-plane', {
    defaultComponents: {
      geometry: {
        primitive: 'plane',
        height: 100,
        width: 100
      },
      rotation: '-90 0 0',
      material: {
        shader: 'standard',
        color: '#5c5e5d', //#8ab39f #001a33 #40E0D0
        metalness: 1,
        roughness: 0.2,
        normalMap: 'url(https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/textures/waternormals.jpg)',
        normalTextureRepeat: '200 200', //50 50
        normalTextureOffset: '0 0',
        normalScale: '0.5 0.5',
        opacity: 0.9 //0.8
      },
      'wobble-normal': {}
    },
  });




  /* ****************************************************** */

 /*  function colorParse(str) {
	return (new window.THREE.Color(str)).getHex();
}

window.AFRAME.registerComponent('a-ada-ocean', {

	schema: {
		src: { type: 'src' },
		width: { default: 1000 },
		depth: { default: 1000 },
		distortionScale: { default: 15 },
		opacity: {
			default: 1.0,
			min: 0, max: 1
		},
		srcHeight: { default: 512 },
		srcWidth: { default: 512 },
		sunDirection: {
			type: 'vec3',
     		default: { x: 1, y: 1, z: 1 }
		},
		sunColor: {
			default: 'grey',
			parse: colorParse
		},
		waterColor: {
			default: '#001e0f',
			parse: colorParse
		},
		light: {
			type: 'selector'
		}
	},

	init: function () {
		this.water = null;
	},

	update: function () {
		this.water = undefined;
		this.el.setObject3D(null);
	},

	tick: function (time) {
		if (this.water) {
			this.water.material.uniforms.time.value = time/5000;
			this.water.render();
		} else if (
			this.el.sceneEl.renderer &&
			this.el.sceneEl.camera
		) {

			this.camera = new window.THREE.PerspectiveCamera();
			this.el.sceneEl.camera.add(this.camera);

			if (this.data.light && this.data.light.object3D) {
				var p = this.data.light.object3D.position.normalize();
				this.data.sunDirection = {
					x: p.x,
					y: p.y,
					z: p.z
				};
			}

			const waterNormals = new window.THREE.TextureLoader().load( this.data.src );
			waterNormals.wrapS = waterNormals.wrapT = window.THREE.RepeatWrapping;
			const water = new window.THREE.Water( this.el.sceneEl.renderer, this.el.sceneEl.camera, this.el.sceneEl.object3D, {
				textureWidth: this.data.srcHeight,
				textureHeight: this.data.srcWidth,
				waterNormals: waterNormals,
				alpha: 	this.data.opacity,
				sunDirection: this.data.sunDirection,
				sunColor: this.data.sunColor,
				waterColor: this.data.waterColor,
				distortionScale: this.data.distortionScale,
				fog: true
			} );
			const mirrorMesh = new window.THREE.Mesh(
				new window.THREE.PlaneBufferGeometry( this.data.width, this.data.depth ),
				water.material
			);
			mirrorMesh.add( water );
			mirrorMesh.rotation.x = - Math.PI * 0.5;
			mirrorMesh.scale.multiplyScalar(10);

			this.water = water;
			this.el.setObject3D('mesh', mirrorMesh);
		}
	},

	remove: function () {
		this.water.remove();
		this.water = undefined;
	}
});

window.AFRAME.registerPrimitive('a-ada-ocean', {
	defaultComponents: {
		'a-ada-ocean': {}
	},
	mappings: {
		width: 'a-ada-ocean.width',
		depth: 'a-ada-ocean.depth',
		color: 'a-ada-ocean.color',
		opacity: 'a-ada-ocean.opacity',
		light: 'a-ada-ocean.light',
		src: 'a-ada-ocean.src',
		'src-height': 'a-ada-ocean.srcWidth',
		'src-width': 'a-ada-ocean.srcHeight'
	}
}); */