import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

// components
import Header from '../components/react-components/Header/Header';

const MainLayout = () => {

    return (
        <div className='w-full min-h-screen bg-base-200'>
            <Header />
            <div className='w-full p-0'>
                <Outlet />
            </div>
        </div>
    )
}

export default MainLayout;