import React from 'react';

// components
import SectionTitle from '../../components/react-components/SectionTitle/SectionTitle';

// assets
import realism_1 from '../../assets/images/contenido/realismo_1.png'
import realism_2_1 from '../../assets/images/contenido/realismo_2_1.png'
import realism_2_2 from '../../assets/images/contenido/realismo_2_2.png'
import realism_3_1 from '../../assets/images/contenido/realismo_3_1.png'
import realism_3_2 from '../../assets/images/contenido/realismo_3_2.png'
import realism_4 from '../../assets/images/contenido/realismo_4.png'

const SectionRealism = () => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 md:pt-16 pb-16 mx-auto flex flex-wrap">
                
                <SectionTitle title='Realismo y rigor científico' className='mb-8'/>

                <div className="flex flex-wrap md:-m-2 -m-1">
                    <div className="flex flex-wrap md:w-1/2 w-full">
                        <div className="md:p-2 p-1 w-1/2">
                            <img alt="gallery" className="rounded-lg w-full object-cover h-full object-center block" src={realism_2_1}/>
                        </div>
                        <div className="md:p-2 p-1 w-1/2">
                            <img alt="gallery" className="rounded-lg w-full object-cover h-full object-center block" src={realism_2_2} />
                        </div>
                        <div className="md:p-2 p-1 w-full">
                            <img alt="gallery" className="rounded-lg w-full h-full object-cover object-center block" src={realism_1} />
                        </div>
                    </div>
                    <div className="flex flex-wrap md:w-1/2 w-full">
                        <div className="md:p-2 p-1 w-full">
                            <img alt="gallery" className="rounded-lg w-full h-full object-cover object-center block" src={realism_4} />
                        </div>
                        <div className="md:p-2 p-1 w-1/2">
                            <img alt="gallery" className="rounded-lg w-full object-cover h-full object-center block" src={realism_3_1} />
                        </div>
                        <div className="md:p-2 p-1 w-1/2">
                            <img alt="gallery" className="rounded-lg w-full object-cover h-full object-center block" src={realism_3_2} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionRealism