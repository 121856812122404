import { useEffect } from 'react';

const CameraAnimation = ({ camera, cameraAnimationDuration, loaded=false, setAnimFinished }) => {


    useEffect(() => {
        if (camera.current && loaded ) {
            

            const handleAnimationComplete = () => {
                //animationsCompleted++;
                //console.log(animationsCompleted)
                setAnimFinished(true);
                camera.current.setAttribute('camera','active:false')

            }

            let initialPos = "0 0.17 0.17"

            camera.current.setAttribute("position", initialPos)
            //camera.current.setAttribute("rotation", "-90 45 0")

            // Animación de rotación de la cámara
            camera.current.setAttribute('animation__1', {
                property: 'rotation',
                dur: cameraAnimationDuration*4,
                from:'-60 0 0',
                to: '-40 0 0',
                easing: 'easeOutCubic',
                loop: false
            });
            //camera.current.addEventListener('animationcomplete__1', handleAnimationComplete);

            camera.current.setAttribute('animation__f', {
                property: 'rotation',
                dur: cameraAnimationDuration,
                from:'-40 0 0',
                to: '-75 0 0',
                easing: 'easeOutQuad',
                loop: false,
                startEvents: "animationcomplete__1"
            });

            camera.current.setAttribute('animation__second', {
                property: 'position',
                dur: cameraAnimationDuration,
                from: initialPos,
                to: '0 0.18 0.05',
                easing: 'linear',
                loop: false,
                startEvents: "animationcomplete__1"
            });
            //camera.current.addEventListener('animationcomplete__2', handleAnimationComplete);
/* 
            // Animación de posición de la cámara
            camera.current.setAttribute('animation__3', {
                property: 'position',
                dur: cameraAnimationDuration,
                from:'0 0.06 0.25',
                to: '0 0.18 0.05',
                easing: 'linear',
                loop: false,
                startEvents: "animationcomplete__second"
            });
            //camera.current.addEventListener('animationcomplete__3', handleAnimationComplete);

            camera.current.setAttribute('animation__4', {
                property: 'rotation',
                dur: cameraAnimationDuration,
                from:'0 0 0',
                to: '-75 0 0',
                easing: 'easeOutQuad',
                loop: false,
                startEvents: "animationcomplete__second"
            });
            //camera.current.addEventListener('animationcomplete__4', handleAnimationComplete); */

            setTimeout(() => {
                handleAnimationComplete()
            }, cameraAnimationDuration*5+700);


            // Iniciar la animación
            //camera.current.emit('startAnimations');
        }
    }, [loaded]);

  return null; // Este componente no renderiza nada en el DOM
};

export default CameraAnimation;