import React from 'react';

// components
import { Logo } from './SectionAlliesList';
import SectionTitle from '../../components/react-components/SectionTitle/SectionTitle';

// assets
import logo_karens_pizza from '../../assets/images/logos/aliados/fundador_karens_pizza.png'

const SectionFoundersList = () => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container flex flex-col px-5 py-16 justify-center items-center">
                
                <SectionTitle title='Miembros fundadores' className='justify-center mb-2' />

                <div className="items-start flex justify-between gap-5 max-md:flex-wrap max-md:justify-center rounded-lg p-2">
                    <Logo image={logo_karens_pizza}/>
                </div>
            </div>
        </section>
    )
}

export default SectionFoundersList;