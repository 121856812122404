import React from 'react';

// assets
import imagen_1 from '../../assets/images/contenido/paisaje_1.png';
import imagen_2 from '../../assets/images/contenido/paisaje_2.png';
import video from '../../assets/images/contenido/paisaje_3.mp4'

// components
import SectionTitle from '../../components/react-components/SectionTitle/SectionTitle';

const SectionLandscapes = () => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 md:pt-16 pb-16 mx-auto flex flex-wrap">
                <div className="flex flex-wrap md:-m-2 -m-1">
                    <div className="flex flex-wrap md:w-1/2 w-full">
                        <div className="md:p-2 p-1 w-full aspect-video flex justify-center items-center">
                            <SectionTitle title='Paisaje Realista' className='justify-center'/>
                        </div>
                        <div className="md:p-2 p-1 w-full aspect-video">
                            <img alt="gallery" className="rounded-lg w-full object-cover h-full object-center block" src={imagen_2} />
                        </div>

                    </div>
                    <div className="flex flex-wrap md:w-1/2 w-full">
                        <div className="md:p-2 p-1 w-full aspect-video">
                            <img alt="gallery" className="rounded-lg w-full h-full object-cover object-center block" src={imagen_1} />
                        </div>
                        <div className="md:p-2 p-1 w-full aspect-video ">
                            <video className="h-full w-full rounded-lg object-cover" controls autoPlay loop muted>
                                <source
                                    src={video}
                                    type="video/mp4"
                                />
                                Sin archivo de video
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionLandscapes;