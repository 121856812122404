import React from 'react';
// import './SectionTitle.css';

const SectionTitle = ({ title, className }) => {
    return (
        <h1 className={`
            ${className}
            text-4xl
            md:text-5xl
            text-primary
            py-4
            w-full 
            h-full
            flex items-center
            text-center 
            italic font-bold
            tracking-wide
            bg-gradient-to-bl from-secondary primary/200 to-primary bg-clip-text text-transparent
        `} 
        // style={{fontSize:'4rem', lineHeight:'4rem'}}
        >
            { title } 
        </h1>
    )
}

export default SectionTitle;