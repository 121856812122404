import React, { useEffect, useRef, useState } from 'react'

const ContactForm = ({ open = false, setOpen }) => {

    const drawerInputRef = useRef(null)
    const [message, setMessage] = useState('');
    const [fromMail, setFromMail] = useState('');

    useEffect(() => {
        if (open) {
            drawerInputRef.current.checked = true;

        } else {
            drawerInputRef.current.checked = false;
        }
    }, [open])


    const onSubmit = () => {
        const formattedMessage = message.split('\n').join('%0D%0A');
        window.location.href = `mailto:gustavo.velasco@reinoalado.com
                            ?subject=Contacto metaverso Reino alado
                            &body=${formattedMessage}
                            %0D%0A%0D%0A Contacto: ${fromMail} 
                            %0D%0A%0D%0A ¡Saludos!`
    }

    return (

        <div className="drawer drawer-end drawer-auto-gutter">
            <input id="my-drawer" type="checkbox" className="drawer-toggle" ref={drawerInputRef} />
            <div className="drawer-content">
            </div>
            <div className="drawer-side pt-20">
                {/* <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label> */}
                <ul className="menu p-4 md:pr-5 w-80 min-h-full bg-primary backdrop-blur-md flex justify-center items-center">

                    <button
                        onClick={() => setOpen(false)}
                        className="btn absolute top-4 right-4 md:right-6 btn-circle btn-outline text-primary bg-white self-end justify-self-start">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                    </button>

                    <div className="container flex flex-col p-4 ">
                        <h2 className="text-secondary font-extrabold font text-xl mb-4">Contáctanos</h2>
                        <p className="leading-relaxed mb-5 text-white">Déjanos conocer tus inquietudes y nos pondremos en contacto contigo</p>
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-white">Correo electrónico</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                autoComplete="off"
                                value={fromMail}
                                onChange={(e) => { setFromMail(e.target.value) }}
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="message" className="leading-7 text-sm text-white">Mensaje</label>
                            <textarea
                                id="message"
                                name="message"
                                autoComplete="off"
                                value={message}
                                onChange={(e) => { console.log(e.target.value); setMessage(e.target.value) }}
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                        <button className="text-white bg-info border-0 py-2 px-6 focus:outline-none hover:bg-secondary rounded text-lg" onClick={onSubmit}>Enviar</button>
                    </div>
                </ul>

            </div>
        </div>

    )
}

export default ContactForm;