import React from 'react';
import PageAnimation from '../../layout/PageAnimation';

// components
import Landing from '../Landing'

const Home = () => {
    return (
        <PageAnimation>
            <div className='h-screen w-full'>
                <Landing />
            </div>
        </PageAnimation>
    )
}

export default Home;