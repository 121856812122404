import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activeSession, setActiveSession] = useState(false)
  const [pending, setPending] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {

    const ls_token = localStorage.getItem('token');
    // const ls_user_data = localStorage.getItem('user_data');

    if (ls_token && ls_token != "null") {
      validateToken(ls_token);
    }
    else {
      setPending(false);
    }

  }, []);

  useEffect( ()=>{
    // localStorage.setItem('token', token)
    // localStorage.setItem('user_data', userData)
  }, [token, userData])

  const validateToken = async (token_to_validate) => {

    const VALID_TOKEN = false
    if (VALID_TOKEN) {

      setToken('token');
      setUserData({});
      setActiveSession(true);

    } else {
      // Error
      // openAlert('Sesión expirada', 'warning')
    }
    setPending(false);
  }


  const login = (token, user_data) => {
    setToken(token);
    setUserData( JSON.parse(user_data) );
    setActiveSession(true)
    navigate("/home");
  }

  const logout = () => {
    localStorage.clear();
    navigate("/autenticacion");
  }

  // ************************HABILITAR CUANDO YA HAYA AUTENTICACION************************
  // if (pending) {
  //   return (<div>cargando autenticacion...</div>)
  // }
  // ************************HABILITAR CUANDO YA HAYA AUTENTICACION************************

  return (
    <AuthContext.Provider
      value={{
        activeSession, token, userData, login, logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};