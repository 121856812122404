import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/aframe-components/InitialMaterials.js'
//import '../components/aframe-components/ColibriMaterial.js'
import '../components/aframe-components/CustomComponents.js'
import '../components/aframe-components/AvesMovement.js'
import CameraAnimation from '../components/react-components/CameraAnimation2.js';
import LoadingScreen from '../components/react-components/loadingScreen/LoadingScreen.js';

//import modelMap from '../assets/models/Mapa.glb'
import petirrojo from '../assets/models/PetirrojoMacho.glb'
import petirrojohembra from '../assets/models/PetirrojoHembra.glb'
import bichofue from '../assets/models/Bichofue.glb'
import carpintero2 from '../assets/models/Carpin2.glb'
import carpintero from '../assets/models/FinalCarpintero.glb'
import cotorra from '../assets/models/Cotorra.glb'
import rupornis from '../assets/models/Rupornis.glb'
import tororoi from '../assets/models/Tororoi.glb'
//import colibri from '../assets/models/Colibri1.glb'
import log from '../assets/models/log.glb'

import CALIMA from '../assets/models/marcadores/CALIMA.glb'
import QUIMBAYA from '../assets/models/marcadores/QUIMBAYA.glb'
import NARINO from '../assets/models/marcadores/NARINO.glb'
import SINU from '../assets/models/marcadores/SINU.glb'
import TAYRONA from '../assets/models/marcadores/TAYRONA.glb'
import VAUPES from '../assets/models/marcadores/VAUPES.glb'

//import terreno2 from '../assets/models/Mapa2.glb'
//import terreno2 from '../assets/models/MapaNuevo.glb'
//import texturaMapa from '../assets/images/test/Textura3.png'

import sky from '../assets/images/sky.jpg'
import cloud from '../assets/images/cloud2.png'
import icon1 from '../assets/images/Ellipse_1.png'
import icon2 from '../assets/images/Ellipse_2.png'
import waternormal from '../assets/images/waternormals.jpg'
import fog from '../assets/images/fog.jpg'
import './Landing.css';


function Landing() {
    const [loaded, setLoaded] = useState(false);
    const [anim1, setAnim1] = useState(false);
    const [animFinished, setAnimFinished] = useState(false); //false

    const cameraAnimationDuration = 3000;
    //const defaultCamera = useRef(null);
    const camera = useRef(null);
    const navigate = useNavigate();



    useEffect(() => {
        const handleSceneLoaded = () => {
          
            setTimeout(() => {
                setLoaded(true)

                setTimeout(() => {
                    setAnim1(true)
                }, 6000);
            }, 4000);

        };
    
        // evento 'loaded' de <a-scene>
        const sceneEl = document.querySelector('a-scene');
        sceneEl.addEventListener('loaded', handleSceneLoaded);
    
        return () => {
          sceneEl.removeEventListener('loaded', handleSceneLoaded);
        };
      }, []);
   

    return (
        <div className="w-full h-full">

            {
                !loaded &&
                <LoadingScreen></LoadingScreen>
            }
            
            <CameraAnimation camera={camera} cameraAnimationDuration={cameraAnimationDuration} loaded={anim1} setAnimFinished={setAnimFinished}/>

           {/*  <div class="interface-container">
                <div class="top-left-section">
                    <p>Reino Alado v0.2</p>
                </div>
                <div class="top-right-section">
                    <button className='ui-button button-1'>¿Qué es reino alado?</button>
                    <button className='ui-button button-2'>¡Apóyanos!</button>
                </div>
                <div class="bottom-center-section">
                    <p>Contenedor vacío</p>
                </div>
            </div> */}

            <a-scene /* stats */ embedded xr-mode-ui="enabled: false" vr-mode-ui="enabled: false" inspector="url: -" fog="type: linear; color: #AAA; near: 0.25; far: 1.1; density:2">
                <a-assets timeout="600000">
                    {/* Agregar modelos a los assets */}
                    {/* <a-asset-item id="bichofue" src={process.env.PUBLIC_URL+"/Assets/Bichofue.glb"}></a-asset-item>
                    <a-asset-item id="petirrojo" src={process.env.PUBLIC_URL+"/Assets/PetirrojoMacho.glb"}></a-asset-item>
                    <a-asset-item id="petirrojohembra" src={process.env.PUBLIC_URL+"/Assets/PetirrojoHembra.glb"}></a-asset-item>
                    <a-asset-item id="carpintero" src={process.env.PUBLIC_URL+"/Assets/FinalCarpintero.glb"}></a-asset-item>
                    <a-asset-item id="carpintero2" src={process.env.PUBLIC_URL+"/Assets/Carpin2.glb"}></a-asset-item>
                    <a-asset-item id="colibri" src={process.env.PUBLIC_URL+"/Assets/Colibri1.glb"}></a-asset-item> */}
                    <img id="sky-img" alt="" src={sky}></img>
                    <img id="cloud-img" alt="" src={cloud}></img>
                    <img id="icon1" alt="" src={icon1}></img>
                    <img id="icon2" alt="" src={icon2}></img>
                    <img id="water-normal" alt="" src={waternormal}></img>
                    <img id="fog" alt="" src={fog}></img>
                </a-assets>
                {/* <a-entity gltf-model="#modelo1" position="0 1.76516 -3.69103" rotation="90 0 0"></a-entity> */}

                <a-sky src="#sky-img"  material="fog: false"></a-sky>
                
                <a-ocean-plane material="normalMap: #water-normal; sphericalEnvMap: #sky-img;" position="0 0.000 0"></a-ocean-plane>
                {/* <a-ada-ocean position="0 0 0" src="#water-normal" opacity="0.6" width="1000" depth="1000"></a-ada-ocean> */}

                {/* <a-entity  geometry="primitive: plane; height: 0.75; width: 1; segmentsWidth: 50; segmentsHeight: 50" rotation="-90 0 0" position="-1 0.017 0" test-materials="type: unplash"></a-entity> */}
                <a-entity  geometry="primitive: plane; height: 0.75; width: 1.3; segmentsWidth: 50; segmentsHeight: 50" rotation="-90 0 0" position="0 0.001 0" test-materials="type: aerial2"></a-entity>
                <a-entity  geometry="primitive: plane; height: 0.75; width: 1.3; segmentsWidth: 50; segmentsHeight: 50" rotation="-90 0 0" position="0 0.001 -0.75" test-materials="type: aerial2i"></a-entity>
                
               
                {/* <a-entity gltf-model={terreno2} position="0 0.018 -0.06" scale="1.5 1.5 1.5" mapa-material={`texture: ${texturaMapa}`}></a-entity> */}

                <a-entity id="mapa-modelo"  position="0.12 0 0" rotation="0 0 0">
                   
                    {
                        anim1 &&
                        <a-entity>
                            {/* <a-image src={icon2} width="0.05" height="0.05" position="-0.225 0.05 -0.045" rotation="-45 0 0" scale="0 0 0"
                                animation__hover="property: scale; to: 1.2 1.2 1.2; dur:200; startEvents: mouseenter" class="clickable"
                                animation__leave="property: scale; to: 1 1 1; dur:200; startEvents: mouseleave"
                                animation__enter="property: scale; to: 1 1 1; dur:2000; easing: easeOutElastic; delay:4000"
                                onClick={() => { window.open(process.env.PUBLIC_URL + '/minigames/fotos/index.html', '_self'); }}>
                                <a-text value="Iniciar" align="center" position="0 0 0" scale="0.05 0.05 0.05"></a-text>
                            </a-image> */}

                                <a-entity position="-0.225 0.05 -0.03" rotation="-45 0 0" scale="0 0 0"
                                    animation__enter="property: scale; to: 1 1 1; dur:2000; easing: easeOutElastic; delay:4000"
                                >

                                    <a-entity gltf-model={CALIMA} scale="0.06 0.06 0.02"
                                        animation__hover="property: scale; to: 0.072 0.072 0.024; dur:200; startEvents: mouseenter" class="clickable"
                                        animation__leave="property: scale; to: 0.06 0.06 0.02; dur:200; startEvents: mouseleave"
                                        animation__startflip="property: rotation; from:0 0 0; to: 0 0 0; dur:200; autoplay:true"
                                        animation__flip1="property: rotation; from:0 0 0; to: 0 180 0; dur: 250; delay:9000; startEvents:animationcomplete__startflip ,animationcomplete__flip2"
                                        animation__flip2="property: rotation; from: 0 180 0;to: 0 0 0; dur: 250; delay:9000; startEvents: animationcomplete__flip1;"
                                        onClick={() => {navigate("/panorama/1") }}></a-entity>

                                    <a-text value="Visitar" align="center" position="0 0.06 0" scale="0.06 0.06 0.06"></a-text>
                                </a-entity>

                                <a-entity position="-0.113 0.078 -0.03" rotation="-45 0 0" scale="0 0 0"
                                    animation__enter="property: scale; to: 1 1 1; dur:2000; easing: easeOutElastic; delay:5000"
                                >

                                    <a-entity gltf-model={QUIMBAYA} scale="0.06 0.06 0.02"
                                        animation__hover="property: scale; to: 0.072 0.072 0.024; dur:200; startEvents: mouseenter" class="clickable"
                                        animation__leave="property: scale; to: 0.06 0.06 0.02; dur:200; startEvents: mouseleave"
                                        animation__startflip="property: rotation; from:0 0 0; to: 0 0 0; dur:200; autoplay:true"
                                        animation__flip1="property: rotation; from:0 0 0; to: 0 180 0; dur: 250; delay:9000; startEvents:animationcomplete__startflip ,animationcomplete__flip2"
                                        animation__flip2="property: rotation; from: 0 180 0;to: 0 0 0; dur: 250; delay:9000; startEvents: animationcomplete__flip1;"
                                        onClick={() => {navigate("/panorama/1") }}></a-entity>

                                    <a-text value="Visitar" align="center" position="0 0.06 0" scale="0.06 0.06 0.06"></a-text>
                                </a-entity>

                                <a-entity position="-0.42 0.05 -0.045" rotation="-45 45 0" scale="0 0 0"
                                    animation__enter="property: scale; to: 1 1 1; dur:2000; easing: easeOutElastic; delay:6000"
                                >

                                    <a-entity gltf-model={SINU} scale="0.06 0.06 0.02"
                                        animation__hover="property: scale; to: 0.072 0.072 0.024; dur:200; startEvents: mouseenter" class="clickable"
                                        animation__leave="property: scale; to: 0.06 0.06 0.02; dur:200; startEvents: mouseleave"
                                        animation__startflip="property: rotation; from:0 0 0; to: 0 0 0; dur:200; autoplay:true"
                                        animation__flip1="property: rotation; from:0 0 0; to: 0 180 0; dur: 250; delay:9000; startEvents:animationcomplete__startflip ,animationcomplete__flip2"
                                        animation__flip2="property: rotation; from: 0 180 0;to: 0 0 0; dur: 250; delay:9000; startEvents: animationcomplete__flip1;"
                                        onClick={() => {navigate("/panorama/1") }}></a-entity>

                                    <a-text value="Visitar" align="center" position="0 0.06 0" scale="0.06 0.06 0.06"></a-text>
                                </a-entity>

                                <a-entity position="-0.37 0.05 0.069" rotation="-45 45 0" scale="0 0 0"
                                    animation__enter="property: scale; to: 1 1 1; dur:2000; easing: easeOutElastic; delay:6500"
                                >

                                    <a-entity gltf-model={NARINO} scale="0.06 0.06 0.02"
                                        animation__hover="property: scale; to: 0.072 0.072 0.024; dur:200; startEvents: mouseenter" class="clickable"
                                        animation__leave="property: scale; to: 0.06 0.06 0.02; dur:200; startEvents: mouseleave"
                                        animation__startflip="property: rotation; from:0 0 0; to: 0 0 0; dur:200; autoplay:true"
                                        animation__flip1="property: rotation; from:0 0 0; to: 0 180 0; dur: 250; delay:9000; startEvents:animationcomplete__startflip ,animationcomplete__flip2"
                                        animation__flip2="property: rotation; from: 0 180 0;to: 0 0 0; dur: 250; delay:9000; startEvents: animationcomplete__flip1;"
                                        onClick={() => {navigate("/panorama/1") }}></a-entity>

                                    <a-text value="Visitar" align="center" position="0 0.06 0" scale="0.06 0.06 0.06"></a-text>
                                </a-entity>

                                <a-entity position="0.215 0.03 -0.03" rotation="-45 -45 0" scale="0 0 0"
                                    animation__enter="property: scale; to: 1 1 1; dur:2000; easing: easeOutElastic; delay:7200"
                                >

                                    <a-entity gltf-model={VAUPES} scale="0.06 0.06 0.02"
                                        animation__hover="property: scale; to: 0.072 0.072 0.024; dur:200; startEvents: mouseenter" class="clickable"
                                        animation__leave="property: scale; to: 0.06 0.06 0.02; dur:200; startEvents: mouseleave"
                                        animation__startflip="property: rotation; from:0 0 0; to: 0 0 0; dur:200; autoplay:true"
                                        animation__flip1="property: rotation; from:0 0 0; to: 0 180 0; dur: 250; delay:9000; startEvents:animationcomplete__startflip ,animationcomplete__flip2"
                                        animation__flip2="property: rotation; from: 0 180 0;to: 0 0 0; dur: 250; delay:9000; startEvents: animationcomplete__flip1;"
                                        onClick={() => {navigate("/panorama/1") }}></a-entity>

                                    <a-text value="Visitar" align="center" position="0 0.06 0" scale="0.06 0.06 0.06"></a-text>
                                </a-entity>

                                <a-entity position="0.05 0.05 -0.15" rotation="-45 -45 0" scale="0 0 0"
                                    animation__enter="property: scale; to: 1 1 1; dur:2000; easing: easeOutElastic; delay:7500"
                                >

                                    <a-entity gltf-model={TAYRONA} scale="0.06 0.06 0.02"
                                        animation__hover="property: scale; to: 0.072 0.072 0.024; dur:200; startEvents: mouseenter" class="clickable"
                                        animation__leave="property: scale; to: 0.06 0.06 0.02; dur:200; startEvents: mouseleave"
                                        animation__startflip="property: rotation; from:0 0 0; to: 0 0 0; dur:200; autoplay:true"
                                        animation__flip1="property: rotation; from:0 0 0; to: 0 180 0; dur: 250; delay:9000; startEvents:animationcomplete__startflip ,animationcomplete__flip2"
                                        animation__flip2="property: rotation; from: 0 180 0;to: 0 0 0; dur: 250; delay:9000; startEvents: animationcomplete__flip1;"
                                        onClick={() => {navigate("/panorama/1") }}></a-entity>

                                    <a-text value="Visitar" align="center" position="0 0.06 0" scale="0.06 0.06 0.06"></a-text>
                                </a-entity>

                           
                        </a-entity>
                    }
                    

                    {
                        loaded &&
                        <a-entity>

                            {/* <a-entity id="fijo1" gltf-model={petirrojo} position="0 0.08 0" scale="0.015 0.015 0.015" animation="property:position; from: 0 0.06 0.2; to: -0.4 0.07 0;  dur:24000; loop:true; easing:linear" animation-mixer="clip: rig|Fly; loop: repeat;" look-at="#pos6"></a-entity> */}
                            {/* <a-entity id="fijo2" gltf-model={bichofue} position="0 0.08 0" scale="0.005 0.005 0.005" animation-mixer="clip: Bichofue_Vuelo; loop: repeat;" animation="property:position; from: -0.2 0.08 0.2; to: 0.3 0.08 -0.25;  dur:30000; loop:true; easing:linear" look-at="#pos3" double-side-material></a-entity> */}

                            <a-entity id="fijo2" gltf-model={carpintero} position="0 0.08 0" scale="0.017 0.017 0.017" animation-mixer="clip: vuelo; loop: repeat" carpintero-material animation="property:position; from: -0.2 0.08 0.2; to: 0.3 0.08 -0.25;  dur:15500; loop:true; easing:linear" look-at="#pos3"></a-entity>
                        </a-entity>
                    }
              
                    {
                        animFinished &&
                        
                            <a-entity>
                                {/* <a-entity position="0 -0.08 0" scale="0.015 0.015 0.015" animation="property:position; from: 0.3 0.08 0.2; to: -0.25 0.08 -0.2;  dur:16000; loop:true; easing:linear" look-at="#pos1">
                                    <a-entity gltf-model={petirrojo}  animation-mixer="clip: rig|Fly; loop: repeat; startAt:400"  animation="property:position; from: 2 0 0; to: -2 0 0;  dur:2000; loop:true; dir: alternate" animation__2="property:rotation; from: 0 0 45; to: 0 0 -45;  dur:2000; loop:true; dir: alternate"></a-entity>
                                </a-entity> */}

                                <a-entity gltf-model={cotorra} position="0 0.08 0" scale="0.0035 0.0035 0.0035" animation-mixer="clip: rig|fly; loop: repeat; startAt:400"  animation="property:position; from: 0.25 0.08 0.2; to: -0.35 0.08 -0.2;  dur:18000; loop:true; easing:linear" look-at="#pos2" cotorra-material></a-entity>
                                {/* <a-entity gltf-model={rupornis} position="0 0.08 0" scale="0.3 0.3 0.3" rupornis-material bird-movement="duration: 5000; delay: 1000"></a-entity> */}
                                <a-entity gltf-model={rupornis} position="0 0.08 0" scale="0.05 0.05 0.05" animation-mixer="clip: Rupornis_vuelo; loop: repeat" animation="property:position; from: 0.05 0.08 -0.3; to: 0.05 0.08 0.3;  dur:10000; loop:true; easing:linear" look-at="#pos7" rupornis-material></a-entity>

                               {/*  <a-entity gltf-model={petirrojo} position="0 0.08 0" scale="0.015 0.015 0.015" animation-mixer="clip: rig|Fly; loop: repeat; startAt:400" animation="property:position; from: 0.3 0.08 0.2; to: -0.25 0.08 -0.2;  dur:16000; loop:true; easing:linear" look-at="#pos1"></a-entity>
                                <a-entity gltf-model={petirrojohembra} position="0 0.08 0" scale="0.015 0.015 0.015" animation-mixer="clip: rig|Fly; loop: repeat; startAt:550" animation="property:position; from: 0.25 0.08 0.2; to: -0.35 0.08 -0.2;  dur:18000; loop:true; easing:linear" look-at="#pos2"></a-entity> */}



                                {/* <a-entity gltf-model={bichofue} scale="0.005 0.005 0.005" animation-mixer="clip: Bichofue_Vuelo; loop: repeat;" animation="property:position; from: -0.4 0.08 -0.2; to: 0.45 0.08 0.2;  dur:29500; loop:true; easing:linear" look-at="#pos5" double-side-material></a-entity> */}
                                {/* <a-entity gltf-model={carpintero2} position="0 -1 0" scale="0.07 0.07 0.07" animation-mixer="clip: Vuelo; loop: repeat;" animation="property:position; from: -0.4 0.08 0.2; to: 0.45 0.08 -0.15;  dur:25350; loop:true; easing:linear; delay:10000" look-at="#pos4"></a-entity> */}
                                

                                


                                {/* <a-entity gltf-model={carpintero} position="0 0.08 0" scale="0.017 0.017 0.017" animation-mixer="clip: vuelo; loop: repeat; startAt:250" carpintero-material animation="property:position; from: 0.05 0.08 -0.3; to: 0.05 0.08 0.3;  dur:18000; loop:true; easing:linear" look-at="#pos7"></a-entity> */}


{/* <a-entity gltf-model={petirrojo} scale="0.015 0.015 0.015" animation-mixer="clip: rig|Fly; loop: repeat; startAt:200" animation="property:position; from: -0.4 0.08 -0.2; to: 0.45 0.08 0.2;  dur:23200; loop:true; easing:linear; delay:15000" look-at="#pos5"></a-entity>
                                <a-entity gltf-model={petirrojohembra} scale="0.015 0.015 0.015" animation-mixer="clip: rig|Fly; loop: repeat; startAt:1000" animation="property:position; from: -0.4 0.08 0.2; to: 0.45 0.08 -0.15;  dur:22000; loop:true; easing:linear; delay:4000" look-at="#pos4"></a-entity> */}



                                <a-image src="#cloud-img" width="0.2" height="0.1" position="0 0.1 0.05" rotation="-90 0 0" material="opacity: 1; transparent: true;" animation="property:position; from: 0.4 0.1 0.05; to: -0.4 0.1 0.05;  dur:80000; loop:true; easing:linear"></a-image>
                                <a-image src="#cloud-img" width="0.2" height="0.1" position="0 0.1 -0.1" rotation="-90 0 0" material="opacity: 1; transparent: true;" animation="property:position; from: 0.4 0.1 -0.1; to: -0.4 0.1 -0.1;  dur:50000; loop:true; easing:linear"></a-image>
                                <a-image src="#cloud-img" width="0.19" height="0.11" position="0 0.11 0" rotation="-90 0 0" material="opacity: 1; transparent: true;" animation="property:position; from: 0.1 0.11 0; to: -0.43 0.11 0;  dur:400000; loop:false; easing:linear"></a-image>

                            </a-entity>

                            
                        
                    }


                    
                    <a-entity id="Posiciones">
                        <a-entity id="pos1" position="-0.25 0.08 -0.2"></a-entity>
                        <a-entity id="pos2" position="-0.35 0.08 -0.2"></a-entity>
                        <a-entity id="pos3" position="0.3 0.08 -0.25"></a-entity>

                        <a-entity id="pos4" position="0.45 0.08 -0.15"></a-entity>
                        <a-entity id="pos5" position="0.45 0.08 0.2"></a-entity>

                        <a-entity id="pos6" position="-0.4 0.07 0"></a-entity>

                        <a-entity id="pos7" position="0.05 0.08 0.3"></a-entity>
                        <a-entity id="pos8" position="-0.05 0.08 0.3"></a-entity>
                    </a-entity>
                    

                    
                </a-entity>

                      
                {/* <a-entity ref={defaultCamera} position="0 0 0" camera="active:false" wasd-controls="enabled:false" look-controls="enabled: false"></a-entity> */}
              {
                animFinished &&
                <a-entity position="0 0 0" camera="active:true" wasd-controls="enabled:false" look-controls="enabled: false; magicWindowTrackingEnabled:false" rotation="0 0 0"
                            orbit-controls=" target: 0 0 0;
                            minDistance: 0.18;
                            maxDistance: 0.25;
                            initialPosition: 0 0 0;
                            minPolarAngle: 15;
                            maxPolarAngle: 40;
                            minAzimuthAngle: 0;
                            maxAzimuthAngle: 0;
                            
                           
                            
                            maxTargetRadius:0.17;
                            ">
                        </a-entity>
                        /*  
                        maxDistance: 0.23;
                        maxPolarAngle: 25;


                        rotateSpeed: 0.2;
                            panSpeed:0.9;
                            dampingFactor:0.1;
                            maxTargetRadius:0.4;*/
              }

                {
                    !animFinished &&
                        
                        
                        <a-entity ref={camera} position="0 0.17 0.17" camera="" wasd-controls="enabled:false" look-controls="enabled: false; magicWindowTrackingEnabled:false">
                            <a-image src="#fog" position="0 0 -0.04" width="0.3" height="0.20"
                            animation__offset="property:material.offset; from: 0 0; to: 0.5 -0.25; dur:100000; easing:linear; loop:true"
                            animation__opacity="property:material.opacity; from: 1; to: 0; dur:2000; easing:linear; delay: 11000"></a-image>
                             
                                {/* <a-entity colibri-material gltf-model={colibri} position="0 -0.01 -0.015" scale="0.005 0.005 0.005" animation-mixer="clip: fly; loop: once; startAt:-4000; duration: 6.5; clampWhenFinished:true"></a-entity> */}
                            
                                <a-entity gltf-model={bichofue} position="0 -0.005 -0.015" scale="0.005 0.005 0.005" rotation="0 20 0" animation-mixer={anim1?"clip: root|Bichofue2_Vuelo; loop: repeat;":"clip: root|Bichofue2_Idle_cerrada; loop: once;"} animation="property:position;  to: 0.1 0.2 0.1;  dur:2000; loop:false; delay:10000"  double-side-material></a-entity>
                                <a-entity gltf-model={log} position="0 -0.005 -0.015" scale="0.005 0.005 0.005" rotation="0 20 0" animation="property:position;  to: 0.1 -0.2 0.1;  dur:2000; loop:false; delay:10000"  double-side-material>

                                </a-entity>

                                <a-entity  gltf-model={log} position="-0.01 -0.006 -0.015" scale="0.005 0.005 0.005" rotation="0 -10 0" animation="property:position;  to: 0.1 -0.2 0.1;  dur:2000; loop:false; delay:10000"  double-side-material>
                                    <a-entity gltf-model={tororoi} position="0 0 0" scale="2 2 2" rotation="0 0 0" animation-mixer="clip: Idle_tororoi; loop: repeat;" rupornis-material></a-entity>
                                </a-entity>
                            {
                                anim1 &&
                                <a-text value="REINO ALADO" align="center" position="0 0 -0.01" scale="0.01 0.01 0.01" opacity="0" color="#ededed" H2e8b57
                                animation__text="property:text.opacity; from: 0; to: 1; dur:2000; delay:2000"
                                animation__text2="property:text.opacity; from: 1; to: 0; dur:2000; delay:4000; startEvents: animationcomplete__text"></a-text>

                            }
                        </a-entity>
                }

                
                


                <a-entity id="mouseCursor" cursor="rayOrigin: mouse" raycaster="far: 10; objects: .clickable"></a-entity>
            </a-scene>

                

        </div>
    );
}

export default Landing;