import React from 'react';
import SectionAlliesList from './SectionAlliesList';
import SectionFoundersList from './SectionFoundersList';

const Allies = () => {
    return (
        <div className='w-full h-full md:p-20 pt-20'>
            <section className="text-gray-600 body-font">
                <div className="container flex flex-col px-5 pt-16 justify-center items-center">

                    <div className="w-full md:w-2/3 flex flex-col mb-8 items-center text-center font-bold">
                        <h1
                            className="
                            title-font 
                            sm:text-4xl text-3xl 
                            mb-4 
                            font-bold 
                            text-primary
                            bg-gradient-to-t from-secondary primary/200 to-primary bg-clip-text text-transparent"
                        >
                            HAZ PARTE DE ESTA INICIATIVA
                        </h1>

                        <h2>
                            Acompáñanos a crear un metaverso  educativo y de entretenimiento en que grandes y chicos puedan conocer, 
                            valorar e interesarse por preservar las aves y la biodiversidad en Colombia.
                        </h2>

                        <p className="my-4 leading-relaxed">
                            Juntos logremos que en el “Reino Alado“ estén representadas todas las aves de Colombia y todas sus regiones.

                        </p>
                        <p className="mb-4 leading-relaxed">
                            Una red de aliados nos permitira crear una experiencia cautivadora, impactante e inolvidable para los 
                            visitantes al COP16 y toda la comunidad anfitriona.
                        </p>
                    </div>


                </div>
            </section>

            <SectionAlliesList/>
            <SectionFoundersList/>
        </div>
    )
}

export default Allies;