import React from 'react';

import SectionTitle from '../../components/react-components/SectionTitle/SectionTitle';

const SectionStages = () => {
    return (
        <section className="text-gray-600 body-font">
            <SectionTitle title='Etapas' className='text-center justify-center my-8'/>
            <ul className="timeline timeline-vertical md:timeline-horizontal flex justify-center w-full">
                <li>
                    <div className="timeline-middle bg-primary p-5 mb-5 rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 640 512"
                            className='text-secondary'>
                            <path fill="currentColor" d="M456 0c-48.6 0-88 39.4-88 88v29.2L12.5 390.6c-14 10.8-16.6 30.9-5.9 44.9s30.9 16.6 44.9 5.9l74.6-57.4h133.1l46.6 113.1c5 12.3 19.1 18.1 31.3 13.1s18.1-19.1 13.1-31.3L311.1 384h44.1l46.6 113.2c5 12.3 19.1 18.1 31.3 13.1s18.1-19.1 13.1-31.3l-42-102C484.9 354.1 544 280 544 192v-72l80.5-20.1c8.6-2.1 13.8-10.8 11.6-19.4C629 52 603.4 32 574 32h-50.1C507.7 12.5 483.3 0 456 0m0 64a24 24 0 1 1 0 48a24 24 0 1 1 0-48" />
                        </svg>
                    </div>
                    <div className="timeline-end timeline-box">
                        Definición de ecosistema y aves
                    </div>
                    <hr className='bg-secondary'/>
                </li>
                <li>
                    <hr className='bg-secondary'/>
                    <div className="timeline-middle bg-primary p-5 mb-5 rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 24 24"
                            className='text-secondary'>
                            <path fill="currentColor" d="M15.5 15H13q-.2 0-.35-.15t-.15-.35v-5q0-.2.15-.35T13 9h2.5q.425 0 .713.288T16.5 10v4q0 .425-.288.713T15.5 15m-5 0H8.25q-.325 0-.537-.213T7.5 14.25t.213-.537t.537-.213H10v-1H9q-.2 0-.35-.15T8.5 12t.15-.35t.35-.15h1v-1H8.25q-.325 0-.537-.213T7.5 9.75t.213-.537T8.25 9h2.25q.425 0 .713.288T11.5 10v4q0 .425-.288.713T10.5 15m3.5-1.5h1v-3h-1zm7-.5q-.425 0-.712-.288T20 12q0-2.875-1.812-5.075T13.55 4.15l.75.75q.275.275.275.7t-.275.7t-.7.275t-.7-.275L9.675 3.075q-.2-.2-.125-.5t.375-.375q.5-.125 1.025-.162T12 2q2.075 0 3.9.788t3.175 2.137T21.213 8.1T22 12q0 .425-.288.713T21 13m-9 9q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12q0-.425.288-.712T3 11t.713.288T4 12q0 2.875 1.813 5.075t4.637 2.775l-.75-.75q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l3.225 3.225q.2.2.125.5t-.375.375q-.5.125-1.025.163T12 22" />
                        </svg>
                    </div>
                    <div className="timeline-end timeline-box">Modelado y animación</div>
                    <hr className='bg-secondary' />
                </li>
                <li>
                    <hr className='bg-secondary' />
                    <div className="timeline-middle bg-primary p-5 mb-5 rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 24 24"
                            className='text-secondary'>
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 6a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-3M3 5h2.5A1.5 1.5 0 0 1 7 6.5v1A1.5 1.5 0 0 1 5.5 9H4h1.5A1.5 1.5 0 0 1 7 10.5v1A1.5 1.5 0 0 1 5.5 13H3m14-6v4a2 2 0 1 0 4 0V7a2 2 0 1 0-4 0M3 16c0 1.657 4.03 3 9 3s9-1.343 9-3" />
                        </svg>
                    </div>
                    <div className="timeline-end timeline-box">
                        Desarrollo de la experiencia
                    </div>
                    <hr className='bg-secondary' />
                </li>
                <li>
                    <hr className='bg-secondary' />
                    <div className="timeline-middle bg-primary p-5 mb-5 rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 512 512"
                            className='text-secondary'>
                            <path fill="currentColor" d="M256 27c-7.2 0-14.6 1.01-19.2 2.54c-2 .69-3.2 1.44-3.8 1.85V169.2c7.7-.1 15.3-.2 23-.2s15.3.1 23 .2V31.39c-.6-.41-1.8-1.16-3.8-1.85c-4.6-1.53-12-2.54-19.2-2.54m-41 18.77c-29.1 9.82-55.3 29.91-76.3 57.63c-15.7 20.7-28.5 45.8-37.1 73.7c40.4-3.9 77.3-6.4 113.4-7.5zm82 0V169.6c36.1 1.1 73 3.6 113.4 7.5c-8.6-27.9-21.4-53-37.1-73.7c-21-27.72-47.2-47.81-76.3-57.63M256 187c-59.6 0-119.2 4-191.01 11.9l-.49.1H64c-.62 0-1.39.1-3.34 2.6c-1.96 2.6-4.22 7.4-6.03 13.5C51.02 227.3 49 244.7 49 262s2.02 34.7 5.63 46.9c1.81 6.1 4.07 10.9 6.03 13.5c1.95 2.5 2.72 2.6 3.34 2.6c111.1 0 142-16 192-16s80.9 16 192 16c.6 0 1.4-.1 3.3-2.6c2-2.6 4.3-7.4 6.1-13.5c3.6-12.2 5.6-29.6 5.6-46.9s-2-34.7-5.6-46.9c-1.8-6.1-4.1-10.9-6.1-13.5c-1.9-2.5-2.7-2.6-3.3-2.6h-.5l-.5-.1C375.2 191 315.6 187 256 187M88 221c9.28 0 17 7.7 17 17s-7.72 17-17 17s-17-7.7-17-17s7.72-17 17-17m168 106c-40.7 0-71.3 12.5-155.8 15.4c8.7 29.7 21.9 56.3 38.5 78.2C169.2 460.8 210.6 485 256 485s86.8-24.2 117.3-64.4c16.6-21.9 29.8-48.5 38.5-78.2C327.3 339.5 296.7 327 256 327m206.5 9.8c-3.9 3.6-8.8 6.2-14.5 6.2h-2.5c1 4 2 8.3 3 12.8c4.4 19.6 8 43.5 6.6 57.2c-1.3 11.9-9 27.9-16.4 43.9c-7.5 16.1-14.5 28.7-14.5 28.7l15.6 8.8s7.4-13.1 15.2-29.9s16.1-32.8 17.9-49.5c2.1-18.6-2.3-42.7-6.8-63.1c-1.2-5.4-2.4-10.5-3.6-15.1M224 349h64v18h-64zm-32 56h128v18H192z"/>
                        </svg>
                    </div>
                    <div className="timeline-end timeline-box">
                        Pruebas y lanzamiento
                    </div>
                </li>
            </ul>
        </section>
    )
}

export default SectionStages;