import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const panoramasData = [
    {
        id: '4',
        title: "KM 18",
        description: "Inicio del recorrido",
        buttonLabel: "Ver mapa",
        enabled: false,
        side: 'timeline-start',
        onButtonClick: null
    },
    {
        id: '2',
        title: "Saladito",
        description: "Parada en Saladito",
        buttonLabel: "Más información",
        enabled: false,
        side: 'timeline-start',
        onButtonClick: null
    },
    {
        id: '3',
        title: "Zoológico",
        description: "Visita al zoológico",
        buttonLabel: "Comprar entradas",
        enabled: false,
        side: 'timeline-start',
        onButtonClick: null
    },
    {
        id: '1',
        title: "Parque de los Gatos",
        description: "Descanso en el parque",
        buttonLabel: "Ver fotos",
        enabled: true,
        side: 'timeline-start',
        onButtonClick: "/minijuegos/fotos"
    },
    {
        id: '5',
        title: "Ermita",
        description: "Visita a la ermita",
        buttonLabel: "Leer sobre la historia",
        enabled: false,
        side: 'timeline-start',
        onButtonClick: null
    },
];


const StepItem = ({ data }) => {
    let { panoId } = useParams();
    const navigate = useNavigate();

    return (
        <li className='grid-cols-1'>
            <hr className="bg-white"/>
            <button className={` btn rounded-full text-lg
                hover:bg-secondary/80 hover:border-white
                ${data.side} text-primary font-semibold badge
                ${data.id == panoId? 'bg-secondary text-white border-white border-4' : 'bg-white'} 
                mr-3 my-5
            `}
                onClick={() => data.onButtonClick?navigate(data.onButtonClick):null}
            >
                {data.title}
            </button>
            <div className="timeline-middle">
                <button className={
                    `btn btn-circle btn-outline btn-xs 
                    hover:border-secondary border-secondary border-4  
                    ${data.id == panoId? 'bg-white': 'bg-transparent'} 
                    hover:bg-white 
                    p-2`
                }
                >
                </button>
            </div>
            {/* <div class="timeline-end timeline-box">.</div> */}
            <hr className="bg-white"/>
        </li>
    )
}

const PanoramaUI = () => {
    // Obtengo el id del panorama de la url
    let { panoId } = useParams();

    return (
        <div className='absolute right-10 top-40 hidden md:inline pointer-events-auto'>
            <ul className="timeline timeline-vertical">
                {panoramasData.map((panorama, i) => <StepItem key={i} data={panorama} />)}
            </ul>
        </div>

    )
}

export default PanoramaUI;