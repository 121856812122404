import React from 'react'

import { NavLink } from 'react-router-dom';

const NavbarOption = ({ title, path }) => {
    return (
        <NavLink
            to={path}
            className={({ isActive, isPending }) => 
                [
                    isActive ? 
                    "font-bold md:text-primary text-primary md:bg-transparent bg-secondary md:underline decoration-secondary underline-offset-8 decoration-2" 
                    : 
                    "font-medium text-white",
                    "mr-6 hover:text-secondary"
                ].join(" ")
                // isActive ? "font-bold text-secondary" : "font-medium text-white" +
                
            }
        >
            {title}
        </NavLink>
    )
}

export default NavbarOption;