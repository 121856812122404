import React from 'react';

const News = () => {
    return (
        <div className='w-full h-full md:p-20 pt-20'>
            <section className="text-gray-600 body-font">
                <div className="container flex flex-col px-5 py-16 justify-center items-center">

                    <div className="w-full md:w-2/3 flex flex-col mb-16 items-center text-center font-bold">
                        <h1
                            className="
                            title-font 
                            sm:text-4xl text-3xl 
                            mb-4 
                            font-bold 
                            text-primary
                            bg-gradient-to-t from-secondary primary/200 to-primary bg-clip-text text-transparent"
                        >
                            NOTICIAS
                        </h1>

                        <h2>Próximamente abriremos el portal de noticias.</h2>

                        <p className="mb-4 leading-relaxed">
                        </p>
                    </div>


                </div>
            </section>
        </div>
    )
}

export default News;